export const i18n_de = {
  dateFormat: 'dd.MM.yyyy - HH:mm:ss',
  emailNotification: {
    list: {
      errorLoadingBatches: 'Die Liste der Mailings konnte nicht geladen werden.',
      errorLoadingBatchGroups: 'Fehler beim laden.',
      date: 'Datum',
      templateName: 'E-Mail-Template',
      header: 'E-Mail-Notifications',
      newNotificationButtonLabel: 'Neue Notification',
      eventName: 'Titel der Veranstaltung',
      sentEmails: 'Versendete E-Mails',
      status: 'Status',
      recipient: 'Empfänger',
      sentAtDate: 'Versanddatum',
    },
    wizard: {
      buttons: {
        next: 'Weiter',
        back: 'Zurück',
        cancel: 'Abbrechen',
        sendEmails: 'E-Mail an Teilnehmer senden',
      },
      step1: {
        label: 'VA-ID & E-Mail-Template',
        selectTemplateLabel: 'E-Mail-Template',
        eventIdLabel: 'VA-ID',
        eventIdHelper: 'Eine oder mehrere VAs durch Leerzeichen oder "," getrennt. Z.B.: "123 456" oder "123,456"',
        templates: {
          AkaLuEventNotificationToolswitch: 'Toolswitch: Versand von Zugangslinks',
          AkaLuEventNotificationTechnicalDifficulties: 'Technische Probleme: Versand von Zugangslinks',
          AkaLuEventNotificationLopOffPeakHours: 'LOP-Randzeiten ',
          AkaLuEventNotificationAlternativeDate: 'Ersatztermin',
          AkaLuEventNotificationHotelNameChange: 'Hotelumfirmierung',
          AkaLuEventNotificationTimeChange: 'Terminwechsel',
          AkaLuEventNotificationGeneralTemplate1: 'Allgemeine Vorlage 1',
          AkaLuEventNotificationGeneralTemplate2: 'Allgemeine Vorlage 2',
          AkaLuEventNotificationGeneralTemplate3: 'Allgemeine Vorlage 3',
          AkaLuEventNotificationGeneralOnlineSupportTemplate1: 'Online Support Vorlage 1',
          AkaLuEventNotificationGeneralOnlineSupportTemplate2: 'Online Support Vorlage 2',
        },
      },
      step2: {
        eventNotFound: 'Es wurde keine Veranstaltung mit der ID "{{eventId}}" gefunden!',
        label: 'Platzhalter',
      },
      step3: {
        label: 'Prüfen & versenden',
        summary: 'Zusammenfassung',
        emailTemplate: 'E-Mail-Template',
        event_one: 'Veranstaltung',
        event_other: 'Veranstaltungen',
        placeholders: 'Platzhalter',
        sendTestEmail: 'Testmail Senden',
        recipientsError: 'Fehler beim laden der Empfänger',
        testEmailSent: 'Test E-Mail versendet!',
        errorSendingTestEmail: 'Fehler beim senden der Test-E-Mail',
      },
      success: {
        header: 'Versenden der E-Mails gestartet',
        backToOverview: 'Zurück zur Übersicht',
      },
    },
  },
  placeholders: {
    AlternativeEventDate: 'Datum - Alternativtermin',
    AlternativeEventLocation: 'Ort - abgesagte Veranstaltung',
    EventTitle: 'Veranstaltungstitel',
    EventStartDate: 'Veranstaltungsbeginn',
    EventEndDate: 'Veranstaltungsende',
    EventDurationInDays: 'Veranstaltungsdauer in Tagen',
    EventFormat: 'Format',
    BookingNumber: 'Buchungsnummer',
    SapEventId: 'Veranstaltungs-Nr.',
    MainRoomUrl: 'URL Hauptraum',
    GroupRoom1Url: 'Gruppenraum 1',
    GroupRoom2Url: 'Gruppenraum 2',
    GroupRoom3Url: 'Gruppenraum 3',
    GroupRoom4Url: 'Gruppenraum 4',
    GroupRoom5Url: 'Gruppenraum 5',
    GroupRoom6Url: 'Gruppenraum 6',
    GroupRoom7Url: 'Gruppenraum 7',
    GroupRoom8Url: 'Gruppenraum 8',
    GroupRoom9Url: 'Gruppenraum 9',
    GroupRoom10Url: 'Gruppenraum 10',
    Kenncode: 'Kenncode',
    StartDate: 'Eintäger oder Startdatum bei Mehrträger',
    EndDate: 'Enddatum (nur für Mehrträger)',
    LocationOfCancelledEvent: 'Ort der abgesagten Veranstaltung',
    OldLocationName: 'Alter Name - Hotel',
    NewHotelName: 'neues Hotel',
    LocationCity: 'Ort des Hotels',
    LocationName: 'Name des Hotels',
    OldEventDate: 'Altes Veranstaltungsdatum',
    OnlineEvent: 'Veranstaltung ist Online',
  },
};
