import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18n_de } from './de';
import { i18n_en } from './en';

i18n.use(initReactI18next).init({
  resources: {
    de: { translation: i18n_de },
    en: { translation: i18n_en },
  },
  initImmediate: true,
  lng: 'de',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
