import { ReactNode } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createGenerateClassName, ThemeProvider } from '@material-ui/core';
import LightHouseTheme from './LightHouseTheme';

interface IThemeWrapperProps {
  children: ReactNode;
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'emailNotifications',
  seed: 'emailNotifications',
});

const LightHouseThemeProvider = ({ children }: IThemeWrapperProps) => {
  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeProvider key="emailNotifications-theme" theme={LightHouseTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default LightHouseThemeProvider;
