import {
  Collapse,
  createStyles,
  IconButton,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotificationTableRow from './NotificationTableRow';
import { getApiService } from '../../utils/apiRequest';
import JWTContext from '../JWTContext';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import Status from './Status';
import { IBatchGroup, INotification } from '../../../../sharedTypes';

interface GroupTableRowProps {
  batchGroup: IBatchGroup;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    content: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    headerCell: {
      borderBottom: 0,
    },
    collapseButton: {
      width: 30,
    },
    notificationsTable: {
      '& tr:last-child td': {
        borderBottom: 0,
      },
    },
    headerRow: {
      borderTop: '1px solid',
      borderColor: theme.palette.grey[300],
    },
  })
);

const GroupTableRow: FunctionComponent<GroupTableRowProps> = (props) => {
  const [open, setOpen] = useState(props.open);

  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [notificationsError, setNotificationsError] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const { getJWT } = useContext(JWTContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (open && !notificationsLoaded) {
      setNotificationsLoading(true);
      const api = getApiService(getJWT);
      api
        .getNotifications(props.batchGroup.batchId, props.batchGroup.id)
        .then((result) => {
          setNotifications(result);
          setNotificationsError(false);
          setNotificationsLoaded(true);
        })
        .catch((error) => {
          setNotificationsError(true);
        })
        .finally(() => {
          setNotificationsLoading(false);
        });
    }
  }, [getJWT, open, notificationsLoaded, props.batchGroup.batchId, props.batchGroup.id]);

  const handleOpenNotifications = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  const getContent = () => {
    if (notificationsLoading) {
      return <LinearProgress />;
    }

    if (notificationsError) {
      return <Alert severity="error">Fehlder beim laden!</Alert>;
    } else {
      return (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} style={{ width: 70 }} />
                <TableCell>
                  <PeopleIcon fontSize="inherit" /> {t('emailNotification.list.recipient')}
                </TableCell>
                <TableCell>
                  <EventIcon fontSize="inherit" /> {t('emailNotification.list.sentAtDate')}
                </TableCell>
                <TableCell align="right">{t('emailNotification.list.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.notificationsTable}>
              {notifications?.map((notification, index) => (
                <NotificationTableRow key={`notification-${index}`} notification={notification} />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      );
    }
  };

  return (
    <Fragment>
      <TableRow className={`${classes.root} ${classes.headerRow}`}>
        <TableCell className={`${classes.headerCell} ${classes.collapseButton}`}>
          <IconButton aria-label="expand row" size="small" onClick={handleOpenNotifications}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.headerCell} component="th" scope="row">
          {props.batchGroup.title}
        </TableCell>
        <TableCell className={classes.headerCell}>
          {props.batchGroup.mailsSent} / {props.batchGroup.mailCount}
        </TableCell>
        <TableCell className={classes.headerCell} align="right">
          {props.batchGroup.mailsSent === props.batchGroup.mailCount ? (
            <Status status="finished" />
          ) : (
            <Status status="pending" />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, borderBottom: 0 }} colSpan={4}>
          {getContent()}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default GroupTableRow;
