import { FunctionComponent } from 'react';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IStatus } from '../../../../sharedTypes';

interface IStatusProps {
  status: IStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
    pending: {
      color: theme.palette.info.main,
    },
    success: {
      color: theme.palette.success.main,
    },
  })
);

const Status: FunctionComponent<IStatusProps> = ({ status }) => {
  const classes = useStyles();

  switch (status) {
    case 'finished':
      return <CheckCircleIcon className={classes.success} />;

    case 'pending':
      return <HourglassFullIcon className={classes.pending} />;

    case 'error':
      return <ErrorIcon className={classes.error} />;
  }
};

export default Status;
