import { createContext } from 'react';

export interface IJWTContextProps {
  getJWT: () => Promise<string | undefined | null>;
}

const JWTContext = createContext<IJWTContextProps>({
  getJWT: async () => {
    console.log('not set');
    return null;
  },
});

export default JWTContext;
