const ColorPalette = {
  colors: {
    crimson: '#e81546',
    emerald: '#2cd96f',
    koromiko: '#ffc263',
    seaBuckthorn: '#f4a730',
    seaGreen: '#318551',
    regalBlue: '#003F71',
    pink: '#ED1F79',
    purple: '#8132ef',
  },
  monochrome: {
    grey10: '#eaedf1',
    grey20: '#dfe1e6',
    grey40: '#c2c6cc',
    grey5: '#f5f7fa',
    grey60: '#939393',
    grey70: '#45484d',
    grey80: '#2b2f33',
    grey90: '#1b2026',
    white: '#fff',
    mischka4: '#f7f7f8',
    mischka3: '#e7e7e8',
    inactiveCardBackground: '#f2f2f2',
    iron: '#d2d4d6',
    manatee: '#8f9299',
    shuttleGrey: '#636871',
    oxfordBlue: '#232a36',
  },
  primary: {
    dark: '#0549c8',
    light: '#397cfa',
    main: '#075BF9',
  },
  secondary: {
    dark: '#b81337',
    light: '#f11f50',
    main: '#e81546',
  },
  text: {
    primary: '#252525',
    secondary: '#8f9299',
    link: '#075BF9',
    main: '#252525',
  },
};

export default ColorPalette;
