import ReactDOM from 'react-dom';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

// import {
//   createBrowserHistory,
//   History,
// } from 'history';
import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import EmailNotifications from './pages/EmailNotifications';
import JWTContext from './components/JWTContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CreateNotificationWizard from './pages/CreateNotificationWizard';
declare global {
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', history: any, microfrontendMeta) => ({
  render: () => {
    ReactDOM.render(
      <LightHouseThemeProvider>
        <JWTContext.Provider value={{ getJWT: microfrontendMeta.getJWT }}>
          <BrowserRouter>
            <Routes>
              <Route path={`/${MICROFRONTEND_ID}`} element={<EmailNotifications />} />
              <Route path={`/${MICROFRONTEND_ID}/new`} element={<CreateNotificationWizard />} />
              <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}`} />} />
            </Routes>
          </BrowserRouter>
        </JWTContext.Provider>
      </LightHouseThemeProvider>,
      document.getElementById(containerId)
    );
  },
});

window.emailNotifications = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {
        groups: [],
      },
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
