import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Status from './Status';
import { INotification } from '../../../../sharedTypes';

interface NotificationRowProps {
  notification: INotification;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    error: {
      color: theme.palette.error.main,
    },
    pending: {
      color: theme.palette.info.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    noBorderBottom: {
      borderBottom: 0,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

const NotificationTableRow: FunctionComponent<NotificationRowProps> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.noBorderBottom} />
        <TableCell scope="row">{props.notification.userId}</TableCell>
        <TableCell>
          {props.notification.sentAt ? format(new Date(props.notification.sentAt), t('dateFormat')) : '---'}
        </TableCell>
        <TableCell align="right">
          <Status status={props.notification.status} />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default NotificationTableRow;
