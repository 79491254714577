import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import {
  Button,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import BatchTableRow from '../components/EmailNotifications/BatchTableRow';
import { getApiService } from '../utils/apiRequest';
import JWTContext from '../components/JWTContext';
import EventIcon from '@material-ui/icons/Event';
import { Alert } from '@material-ui/lab';
import MailIcon from '@material-ui/icons/Mail';
import { MICROFRONTEND_ID } from '../config';
import { useTranslation } from 'react-i18next';
import { IBatch } from '../../../sharedTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newButton: {
      alignItems: 'flex-end',
    },
    batchTable: {
      '& tr:last-child td': {
        borderBottom: 0,
      },
    },
  })
);

const EmailNotifications: FunctionComponent = () => {
  const classes = useStyles();

  const [batches, setBatches] = useState<IBatch[]>();
  const [batchesLoading, setBatchesLoading] = useState(false);
  const [batchesError, setBatchesError] = useState(false);
  const { getJWT } = useContext(JWTContext);
  const { t } = useTranslation();

  useEffect(() => {
    setBatchesLoading(true);
    const api = getApiService(getJWT);
    api
      .getBatches()
      .then((result) => {
        setBatches(result);
        setBatchesError(false);
      })
      .catch((error) => {
        setBatchesError(true);
      })
      .finally(() => {
        setBatchesLoading(false);
      });
  }, [getJWT]);

  const getContent = () => {
    if (batchesLoading) {
      return <LinearProgress />;
    }

    if (batchesError) {
      return <Alert severity="error">{t('emailNotification.list.errorLoadingBatches')}</Alert>;
    } else {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <EventIcon fontSize="inherit" /> {t('emailNotification.list.date')}
                </TableCell>
                <TableCell align="right">{t('emailNotification.list.templateName')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.batchTable}>
              {batches?.map((row) => (
                <BatchTableRow key={row.id} batch={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return (
    <Fragment>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h5">{t('emailNotification.list.header')}</Typography>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={4}>
          <Button
            startIcon={<MailIcon />}
            className={classes.newButton}
            href={`/${MICROFRONTEND_ID}/new`}
            color="primary"
            variant="contained"
          >
            {t('emailNotification.list.newNotificationButtonLabel')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EmailNotifications;
