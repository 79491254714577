import { FunctionComponent, useState } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import SuccessPage from './SuccessPage';
import { IEventPlaceholders } from '../../../sharedTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    content: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export interface IEventNotificationWizardData {
  eventId?: string;
  emailTemplate?: string;
  placeholders: Record<string, string | string[] | IEventPlaceholders[]>;
}

export interface IEmailNotificationStepProps {
  onSetData: () => void;
}

const CreateNotificationWizard: FunctionComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [eventId, setEventId] = useState<string[]>([]);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [placeholders, setPlaceholders] = useState<Record<string, string | number | boolean>>({});
  const [eventsPlaceholders, setEventsPlaceholders] = useState<IEventPlaceholders[]>([]);

  const { t } = useTranslation();
  const steps = [
    t('emailNotification.wizard.step1.label'),
    t('emailNotification.wizard.step2.label'),
    t('emailNotification.wizard.step3.label'),
  ];

  const handleNextStep1 = (eventId: string[], emailTemplate: string) => {
    setEventId(eventId);
    setEmailTemplate(emailTemplate);
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleNextStep2 = (
    placeholders: Record<string, string | number | boolean>,
    eventsPlaceholders: IEventPlaceholders[]
  ) => {
    setPlaceholders(placeholders);
    setEventsPlaceholders(eventsPlaceholders);
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleNextStep3 = () => {
    setPlaceholders(placeholders);
    setEventsPlaceholders(eventsPlaceholders);
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleBack = () => {
    setActiveStep((currentStep) => currentStep - 1);
  };

  const classes = useStyles();

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <Step1 eventIds={eventId} emailTemplate={emailTemplate} handleNext={handleNextStep1} />;
      case 1:
        return (
          <Step2
            handleBack={handleBack}
            eventIds={eventId}
            emailTemplate={emailTemplate}
            placeholders={placeholders}
            eventsPlaceholders={eventsPlaceholders}
            handleNext={handleNextStep2}
          />
        );
      case 2:
        return (
          <Step3
            handleBack={handleBack}
            eventIds={eventId}
            emailTemplate={emailTemplate}
            placeholders={placeholders}
            eventsPlaceholders={eventsPlaceholders}
            handleNext={handleNextStep3}
          />
        );
      case 3:
        return <SuccessPage />;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} elevation={1}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStep)}
    </div>
  );
};

export default CreateNotificationWizard;
