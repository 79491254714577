import { Box, Button, Paper, Typography, useTheme } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { MICROFRONTEND_ID } from '../config';

const SuccessPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box component={Paper} my={2} p={3} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2" style={{ margin: '0 0 1.5rem 0' }}>
          {t('emailNotification.wizard.success.header')}
        </Typography>
        <CheckCircleOutlineIcon fontSize="large" style={{ fontSize: 80 }} htmlColor={theme.palette.success.main} />
      </Box>
      <Box textAlign="right">
        <Button variant="contained" color="primary" href={`/${MICROFRONTEND_ID}`}>
          {t('emailNotification.wizard.success.backToOverview')}
        </Button>
      </Box>
    </>
  );
};

export default SuccessPage;
