import { FunctionComponent, useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Grid, makeStyles, MenuItem, Paper, TextField, Theme } from '@material-ui/core';
import StepperButtons from './StepperButtons';
import { EMAIL_TEMPLATES } from '../utils/emailTemplates';

const TEMPLATE_KEYS = Object.keys(EMAIL_TEMPLATES);

interface IStep1Props {
  handleNext: (eventId: string[], emailTemlate: string) => void;
  eventIds: string[];
  emailTemplate: string;
}

const Step1: FunctionComponent<IStep1Props> = (props) => {
  const [selectTemplateOpen, setSelectTemplateOpen] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(props.emailTemplate);
  const [eventIds, setEventIds] = useState(props.eventIds);

  const { t } = useTranslation();

  const handleTemplateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmailTemplate(event.target.value as string);
  };

  const handleEventIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const eventIds =
      String(event.target.value)
        .split(/[\s,]+/)
        ?.map((item) => item.trim()) || [];
    setEventIds(eventIds);
  };

  const styleInput = {
    textAlign: 'right',
    alignSelf: 'center',
    paddingRight: '20px',
    '& label': {
      fontWeight: '800',
    },
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        display: 'block',
        marginTop: theme.spacing(2),
      },
      formControl: {
        width: '100%',
      },
      formRow: {
        padding: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  return (
    <>
      <Box component={Paper} my={2} p={3}>
        <Grid item container className={classes.formRow}>
          <Grid item xs={2} component={Box} sx={styleInput}>
            <InputLabel id="select-email-template">{t('emailNotification.wizard.step1.eventIdLabel')}</InputLabel>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="event-id"
              // value={eventIds}
              onChange={handleEventIdChange}
              helperText={t('emailNotification.wizard.step1.eventIdHelper')}
              className={classes.formControl}
            />
          </Grid>
        </Grid>
        <Grid item container className={classes.formRow}>
          <Grid item xs={2} component={Box} sx={styleInput}>
            <InputLabel id="select-email-template">
              {t('emailNotification.wizard.step1.selectTemplateLabel')}
            </InputLabel>
          </Grid>
          <Grid item xs={10}>
            <Select
              className={classes.formControl}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={selectTemplateOpen}
              onClose={() => setSelectTemplateOpen(false)}
              onOpen={() => setSelectTemplateOpen(true)}
              value={emailTemplate}
              onChange={handleTemplateChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {TEMPLATE_KEYS.map((templateKey) => (
                <MenuItem value={templateKey} key={templateKey}>
                  {t(`emailNotification.wizard.step1.templates.${templateKey}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>

      <StepperButtons
        nextButtonDisabled={!emailTemplate || !eventIds}
        handleNext={() => props.handleNext(eventIds, emailTemplate)}
        nextLabel={t('emailNotification.wizard.buttons.next')}
        backLabel={t('emailNotification.wizard.buttons.back')}
      />
    </>
  );
};

export default Step1;
