interface IPlaceholder {
  id: string;
  required: boolean;
  datatype: 'string' | 'date' | 'number' | 'link';
}

export const EMAIL_TEMPLATES: Record<string, IPlaceholder[]> = {
  AkaLuEventNotificationToolswitch: [
    {
      id: 'MainRoomUrl',
      required: true,
      datatype: 'link',
    },
    {
      id: 'GroupRoom1Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom2Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom3Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom4Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom5Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'Kenncode',
      required: false,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationTechnicalDifficulties: [
    {
      id: 'MainRoomUrl',
      required: true,
      datatype: 'link',
    },
    {
      id: 'GroupRoom1Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom2Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom3Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom4Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom5Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'Kenncode',
      required: false,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationLopOffPeakHours: [
    {
      id: 'MainRoomUrl',
      required: true,
      datatype: 'link',
    },
    {
      id: 'GroupRoom1Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom2Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom3Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom4Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom5Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'Kenncode',
      required: false,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationAlternativeDate: [
    {
      id: 'AlternativeEventLocation',
      required: true,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationHotelNameChange: [
    {
      id: 'OldLocationName',
      required: true,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationTimeChange: [
    {
      id: 'OldEventDate',
      required: true,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationGeneralTemplate1: [],

  AkaLuEventNotificationGeneralTemplate2: [],

  AkaLuEventNotificationGeneralTemplate3: [],

  AkaLuEventNotificationGeneralOnlineSupportTemplate1: [
    {
      id: 'MainRoomUrl',
      required: true,
      datatype: 'link',
    },
    {
      id: 'GroupRoom1Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom2Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom3Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom4Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom5Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'Kenncode',
      required: false,
      datatype: 'string',
    },
  ],

  AkaLuEventNotificationGeneralOnlineSupportTemplate2: [
    {
      id: 'MainRoomUrl',
      required: true,
      datatype: 'link',
    },
    {
      id: 'GroupRoom1Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom2Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom3Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom4Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'GroupRoom5Url',
      required: false,
      datatype: 'link',
    },
    {
      id: 'Kenncode',
      required: false,
      datatype: 'string',
    },
  ],
};
