import { Box, Button, Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MICROFRONTEND_ID } from '../config';

interface IStepperButtonProps {
  backLabel: string;
  nextLabel: string;
  handleBack?: () => void;
  handleNext?: () => void;
  nextButtonDisabled: boolean;
}

const StepperButtons: FunctionComponent<IStepperButtonProps> = ({
  backLabel,
  handleBack,
  handleNext,
  nextLabel,
  nextButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Button href={`/${MICROFRONTEND_ID}`} variant="outlined" color="primary">
          {t('emailNotification.wizard.buttons.cancel')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end" alignContent="center">
          <Button
            disabled={typeof handleBack === 'undefined'}
            onClick={handleBack}
            variant="outlined"
            style={{ marginRight: '.5rem' }}
            color="primary"
          >
            {backLabel}
          </Button>
          <Button
            disabled={nextButtonDisabled || typeof handleNext === 'undefined'}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {nextLabel}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StepperButtons;
